/* You can add global styles to this file, and also import other style files */
body {
margin: 0;
padding: 0;
margin-top: -152px;
} 
.border-radius svg{
    border-radius: 35px;
    box-shadow: rgb(0 0 0 / 73%) 0px 8px 24px;
}

.divemotor-style{
    background-image: url(/assets/fondo-vcard-desktop.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin-top: 152px;   
    }
.andesmotor-style{
    background-image: url(/assets/fondo-vcard-andes.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    }

.andesmotor-style .container,.andesmotor-style .container-datos-contacto, .andesmotor-style .container-red-social{
    background: #FFFFFF !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
.andesmotor-style h1, .andesmotor-style h2, .andesmotor-style h3, .andesmotor-style h4, .andesmotor-style h5, .andesmotor-style p, .andesmotor-style .row-text-red-social{
    color: #333333 !important;
    }
.andesmotor-style .icon-list-profil{
    background:#012169 !important;
}
.andesmotor-style .button-direction{
    background:#012169 !important;
}
.andesmotor-style .boton-qr{
    background: #13216a !important; 
}
.andesmotor-style .add-contact-box{
    background: #13216a !important;
}
.andesmotor-style .add-contact-img img{
    filter: invert(38%) !important;
}
.andesmotor-style .dive{
    display: none !important;
}
.divemotor-style .andes{
    display: none !important;
}
.andesmotor-style .not-found h1{
    color: #012169 !important;
}
.andesmotor-style .close-button{
    background: #13216a !important;
}
.andesmotor-style .border-radius svg{
    box-shadow: rgb(0 0 0 / 32%) 0px 8px 24px !important;
}
.swal2-title{
font-family: 'Work Sans' !important;
font-weight: 600 !important;
font-size: 16px !important;
color: #737373 !important;
padding: 0 !important;
}
.swal2-html-container{
    font-family: 'Work Sans' !important;
font-weight: 500 !important;
font-size: 16px !important;
color: #737373 !important;
}
.swal2-actions{
    display: none !important;
}

.swal2-container.swal2-center>.swal2-popup{  
box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.07) !important;
border-radius: 5px !important;
}
.andesmotor-style .boton-to-share{
    background: #13216a !important;
}
.andesmotor-style .box-login-profile{
    background: #fff !important;
}
.andesmotor-style .logo-divi{
    display: none !important;
}
.andesmotor-style .logo-andes{
    display: block !important;
}
.andesmotor-style .logo-padlock{
    filter: invert(1) !important;
}
.andesmotor-style .row-login-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.andesmotor-style .change-field label{
    color: #333333 !important;
}
.andesmotor-style .change-field input{
    border: 1px solid #cacaca !important;
    color: #333333 !important;
}
.andesmotor-style .button-save button, .andesmotor-style .icon-edit-img,.andesmotor-style .button-microsoft {
    background: #012169 !important;
}

@media (max-width: 600px) {
    .andesmotor-style .footer-mobile{
        background: #ffffff !important;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    .andesmotor-style .footer-box-to-share{
        background: #13216a !important;
    }
    .andesmotor-style .footer-box-qr img, .andesmotor-style .footer-box-to-share img, .andesmotor-style .footer-box-add-contact img{
        filter: brightness(0) invert(1) !important;
    }
    .andesmotor-style .footer-box-qr span, .andesmotor-style .footer-box-to-share span, .andesmotor-style .footer-box-add-contact span{
        color: #fff !important;
    }
    .andesmotor-style .container, .divemotor-style .container{
        margin-top: 160px !important;
    }

}
